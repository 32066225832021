@import '~@styles/base.scss';

.content {
  width: 95%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
}

.edit-button {
  background: transparent;
}

.row {
  display: flex;
}

.row-space {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card {
  background-color: white;
  border-radius: 12px;
  min-height: 250px;

  &--small {
    width: 252px;
    height: 206px;
  }

  &__header {
    margin-top: 13px;

    // TODO: wrap in mixin
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: black;
  }

  &__content {
    padding: 20px;

    &--summary {
      padding-left: 21px;

      // TODO: wrap in mixin
      display: flex;
      flex-direction: column;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      letter-spacing: 0.1px;
      color: #777777;
      overflow: scroll;
      height: 240px;
      overflow-x: hidden;

      p {
        margin: 16px;
      }
    }

    &--details {
      padding-top: 10px;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      height: 80%;
    }

    &--contact {
      padding-left: 18px;
      display: flex;
      flex-direction: column;
    }
  }
}

.btn {
  @include btn;
  color: #2196f3;
  background: transparent;
  width: fit-content;
  margin-top: 50px;

  &--primary {
    margin-top: 17px;
    margin-right: 17px;
    width: fit-content;
    @include primary-btn;

    &:disabled {
      background-color: #777777;
      cursor: not-allowed;
    }
  }

  &--secondary {
    margin-top: 17px;
    margin-right: 17px;
    width: fit-content;
    @include secondary-btn;
  }
}

.care-plan {
  display: flex;
  align-items: center;
  margin-top: 16px;

  &__icon {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    margin-right: 11px;
  }

  &__text {
    // TODO: wrap in mixin
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;

    color: #777777;
  }
}

.red {
  background: #ffefeb;
}

.blue {
  background: #ebf7ff;
}

.back {
  cursor: pointer;
}

.grid-container {
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-template-rows: repeat(auto-fit, minmax(auto, 300px));
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.ecg {
  margin-top: 20px;
}

.modal {
  &__row {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
  }
}

.pre-modal-btn {
  background-color: transparent;
  color: #777777;
  text-align: left;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  padding: 10px;
  margin-right: 30px;

  &:hover {
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
  }
}
