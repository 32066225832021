.fileDetails {
  padding: 20px;
}

.fileDetails h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.fileDetails p {
  font-size: 16px;
}
