@import '~@styles/variables.scss';
@import '~@styles/base.scss';

.box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  border: 2px solid #000;
  border-radius: 10px;
  box-shadow: 24;
  background-color: white;
  padding: 12px;
}
.header {
  font-family: 'Nunito Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: black;
  margin-bottom: 22px;
}

.row {
  display: flex;
  margin-bottom: 22px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;

  button {
    margin-left: 15px;
  }
}
