@import '~@styles/base.scss';

.header {
  color: #25282b;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 46px;
  letter-spacing: 0.2px;
  margin-bottom: 24px;
}

form {
  background: #ffffff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
  padding: 18px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #52575c;
  letter-spacing: 0.1px;
  margin-bottom: 20px;
}

.row {
  display: flex;
  align-items: center;
  width: 400px;
  margin: 10px;
  padding: 10px 0;
  border: 1px solid black;
}

.params_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row > * {
  margin: 0 0 0 20px;
}

.row_title {
  margin-left: 3px;
  margin-bottom: 2px;
}

.param {
  flex: 1 1 0;
  border: 1px solid #c4c4c4;
  padding: 5px;
  border-radius: 5px;
  min-height: 200px;
  &__range {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  &__input {
    width: 100%;
  }

  &__threshold_input {
    width: 100%;
    margin-top: 3px !important;
  }
}

.param > * {
  margin-bottom: 20px;
}

.surveys {
  display: flex;

  &__id {
    width: 40%;
  }

  &__frequency {
    width: 60%;
    margin-left: 5px;
  }
}

.upload {
  border: 1px solid red;
  width: 515px;
  height: 247px;
  background: #fafafa;
  border: 1px dashed #000000;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

.save {
  justify-content: flex-end;
}

.save__btn {
  @include primary-btn;
  margin-top: 10px;
  margin-left: 10px;

  &:disabled {
    background-color: #777;
    cursor: not-allowed;
  }
}
